import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    zIndex: 10,
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  links: {
    marginTop: 16,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    marginRight: 10,
    color: 'white',
    textDecoration: 'none',
  },
}));

const Footer = ({ siteTitle }) => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Typography variant="body1" align="center" gutterBottom>
          <strong>Sheffield Consulting</strong>
        </Typography>
        <Typography variant="body2" align="center">
          {'Copyright © '}
          Sheffield Consulting {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
