import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Link } from 'gatsby';

import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  title: {
    color: 'white',
    overflow: 'visible',
    fontFamily: ['Barlow', 'Roboto', 'serif'].join(','),
    textAlign: 'left',
    fontSize: 18,
    [theme.breakpoints.up('md')]: {
      fontSize: 23,
    },
  },
  titleBlue: {
    color: theme.palette.primary.main,
    overflow: 'visible',
    textAlign: 'left',
    fontFamily: ['Barlow', 'Roboto', 'serif'].join(','),
    fontSize: 18,
    [theme.breakpoints.up('md')]: {
      fontSize: 23,
    },
  },
}));

function ElevationScroll(props) {
  const theme = useTheme();
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    style: trigger
      ? {
          backgroundColor: 'white',
          color: theme.palette.primary.main,
          transition: 'background-color 0.5s ease',
        }
      : {
          transition: 'background-color 0.5s ease',
        },
  });
}

export default function Navbar(props) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const classes = useStyles();
  return (
    <ElevationScroll {...props}>
      <AppBar>
        <Toolbar>
          <Link to="/" className={classes.link}>
            <Typography
              className={trigger ? classes.titleBlue : classes.title}
              variant="h5"
            >
              Sheffield Consulting
            </Typography>
          </Link>
          <div style={{ flex: 1 }} />
          <Typography>Contact 646-801-5808</Typography>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}
